.parallax-element{
    height: 100%;
}

.parallax-title {
    color: white;
    font-size: 800%;
    font-family: 'Pinyon Script', cursive;
}
.bordered{
    color: #2f3234	;

    border-top-color: #2f3234;
    border-top-width: 1px;
    border-top-style: solid;

    border-bottom-color: #2f3234;
    border-bottom-width: 1px;
    border-bottom-style: solid;

    font-family: 'Pinyon Script', cursive;
}
.large{
    font-size: 1000%;
}
.parallax-title-container{
    position: absolute;
    top: 50%;
    left: 0%;
    width: 100%;
    text-align: center;
}

.parallax{
    position: relative;

    /* Set a specific height */
    min-height: 100%;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    opacity: 0.85;
}

@media only screen and (max-width: 768px ) {
    .large{
        font-size: 470%;
    }

    .parallax-title{
        font-size: 400%;
    }

    .parallax-title-container:not(.title-element){
        top: 30%;
    }
}