.card-container {
    padding: 5px;
}

.card {
    border-radius: 2px;
    background-color: white;
    height: 300px;
    position: relative;
    color: white;
    background-repeat: no-repeat;
    background-size: cover;
}

.card .caption {
    text-align: center;
    padding-top: 50px;
}

.card .caption span {
    font-size: 200%;
    border-bottom-color: white;
    border-bottom-width: 1px;
    border-bottom-style: solid;

    border-top-color: white;
    border-top-width: 1px;
    border-top-style: solid;

    font-family: 'Pinyon Script', cursive;
}

.footer {
    position: absolute;
    left: 10px;
    bottom: 5px;
}

.footer span {
    margin-left: 3px;
    margin-right: 3px;
}

.card-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    transition: background-color, opacity 0.5s;
}

.card:not(.card--nobg) .card-overlay:hover {
    opacity: 0;
}