.parallax-container {
    height: 100%;
}

section{
    padding-bottom: 50px;
}

footer{
    color: #333333;
    text-align: center;
    margin-top: 10px;
}