.form-control{
    border-top: none;
    border-left: none;
    border-right: none;
    box-shadow: none;
    border-radius: 0px;
    color:black;
    background-color: transparent;
}

.form-control:focus{
  box-shadow: none;
}
.btn-default{
    border-top: none;
    border-left: none;
    border-right: none;
    box-shadow: none;
    border-radius: 0px;
    outline: none;
    background-color: transparent;
}

.btn-default:focus{
    box-shadow: none;
    outline: none;
}