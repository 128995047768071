.navbar.visible{
    opacity: 1;
}

.navbar.not-visible{
    opacity: 0;
}

.navbar{
    transition: opacity 0.5s;
}

.navbar-header a span{
    color:#333333;
    border-top-color: #333333;
    border-top-width: 1px;
    border-top-style: solid;
    border-bottom-color: #333333;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    font-family: 'Pinyon Script', cursive;
    font-size: 150%;
}