.profile{
    border-style: solid;
    border-width: 1px;
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
}

.description{
    text-align: center;
}
.description::first-letter {
    font-size: 200%;
}

@media only screen and (max-width: 768px) {
    .profile {
        width: 50%;
        height: 50%;
    }
}