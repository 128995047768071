.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.gallery-image-link {
  width: 100%;
  height: 100%;
}
