body {
  margin: 0;
  padding: 0;
  font-family: 'Abel', sans-serif;
}

html,body,#root{
    height: 100%;
}

html{
    background-color: #fafafa;
}